import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import device from '../helpers/breakpoints';
import Layout from '../components/layout';
import Nav from '../components/nav';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Anchor from '../helpers/anchor';

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        title
        description
      }
    }
    image: file(relativePath: { eq: "laptops-xprtz1.jpg" }) {
      publicURL
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jasper: file(relativePath: { eq: "dit_is_jasper.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dennis: file(relativePath: { eq: "en_ik_ben_dennis.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "symboltext-xprtz-white.svg" }) {
      publicURL
    }
    arrowdown: file(relativePath: { eq: "arrowdown.svg" }) {
      publicURL
    }
  }
`;

const BodyContainer = styled.div`
  max-width: 728px;
  padding: 0 1rem;
  margin: 8rem auto 4rem;

  @media ${device.tablet} {
    padding: 0 2rem;
    max-width: 960px;
  }

  @media ${device.laptop} {
    padding: 0 8rem;
    max-width: 1280px;
  }

  @media ${device.laptopL} {
    padding: 0 8rem;
    max-width: 1440px;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.022em;
  }

  h2 {
    font-size: 26px;
    line-height: 1.18;
    letter-spacing: -0.022em;
    margin-top: 1.72em;

    + p {
      margin-top: 0.46em;
    }
  }

  p {
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.004em;
    margin-top: 2em;
  }
`;

const Emphasis = styled.span`
  font-weight: bold;
  margin-bottom: 0.2em;
  display: inline-block;
`;

const WerkenBijPage = ({ data }) => {
  return (
    <Layout
      metadata={{ ...data.site.siteMetadata, image: data.image.publicURL }}
    >
      <Nav Root="/" Fixed="true" />
      <BodyContainer>
        <Anchor id="banner-anchor"></Anchor>
        <h1>Trainingen</h1>
        <p>
          <Emphasis>Geen best practices of blabla rondom documentatie, maar echte praktijkervaring!</Emphasis>
        </p>
        <p>
          Hoe richten anderen hun Azure Landing zone in, hoe start ik met microservices of een specifieke .NET uitdaging? Met onze ervaring bij meerdere top 500 bedrijven, MKB+ organisaties en alles daartussen, in bijna elke denkbare branche, weten wij het antwoord! Elke situatie vraagt om een andere aanpak en komt met zijn eigen valkuilen. En hoe stom het ook klinkt maar ook wij vallen wel eens in deze kuilen. Gelukkig zijn we er ook weer snel uitgeklommen en nemen we deze ervaring weer mee naar de volgende omgeving!
        </p>
        <p>
          Zou je ook graag bekende valkuilen willen afvangen en échte praktijktips willen gebruiken voor jouw eigen organisatie; wij delen onze kennis! In de vorm van een praktijkgerichte en interactieve workshop nemen we software development teams mee in wat zij kunnen verwachten. Dit kan bijvoorbeeld gaan om het inrichten van microservices, het beginnen met Clean Architecture, het starten met Azure als landing zone, het zo optimaal inrichten van een CI/CD omgeving, noem maar op!
        </p>
        <ul>
          <li><Emphasis>Starten met microservices in .net</Emphasis></li>
          <p css={css`margin-bottom: 2em;`}>
            Microservices zijn helemaal hip. In deze praktische training zullen we starten met het omzetten een bestaande applicatie naar een aantal microservices om zo inzicht te krijgen in wat deze manier van applicatie ontwerp kan betekenen voor je organisatie. We ervaren wat de voordelen zijn maar zullen zien dat er ook andere uitdagingen bijkomen die in eerste instantie misschien als een verrassing overkomen.
          </p>
          <li><Emphasis>Professionele microservices in .net</Emphasis></li>
          <p css={css`margin-bottom: 2em;`}>
            Microservices maken grote beloften en krijgen veel buzz om innovatie en flexibiliteit te verbeteren. Helaas is het opknippen van een grote applicatie in kleinere delen geen wondermiddel. Er zijn veel manieren om het ontwerp van microservices te verknoeien. We gaan niet vertellen wat er gebeurt als je het ontwerp verkeerd hebt, je hebt waarschijnlijk iets van die lelijkheid al gezien. We gaan wel bespreken en uitleggen hoe deze te vermijden zijn en zullen nadenken over manieren om microservices te ontwerpen die hun beloftes wel nakomen.
          </p>
          <li><Emphasis>Clean architecture in .net</Emphasis></li>
          <p css={css`margin-bottom: 2em;`}>
            Code is iets dat in een organisatie vaak lang blijft leven. Daarom is het belangrijk dat er naast de functionele eisen, doet de code wat ze hoort te doen, ook aandacht is voor de structuur. De manier waarop de code is opgezet en hoe het project is ingericht. Op een bepaald moment wordt code meer gelezen dan dat het wordt aangepast. Dan is het wel zo fijn om de code snel te kunnen doorgronden. In deze workshop gaan we aan de slag om een project om te zetten naar clean architecture om vervolgens nieuwe business requirements te implementeren op de oude code base en op de nieuwe.
          </p>
          <li><Emphasis>Starten met je Azure Landing Zone</Emphasis></li>
          <p css={css`margin-bottom: 2em;`}>
            Landing zones zijn een goede manier om grip te houden op de workloads en de onderliggende infrastructuur in je Azure cloud omgeving, maar hoe begin je met zo'n landing zone? Hoe zorg je ervoor dat teams de landing zone en cloud deployments adopteren? Hoe houd je grip op resources, maar zit je tegelijkertijd je development teams niet in de weg?
            Allemaal vragen die aan bod komen in deze interactieve workshop.</p>
          <li><Emphasis>Krijg grip op je Azure Cost management</Emphasis></li>
          <p css={css`margin-bottom: 2em;`}>
            De cloud is een prachtig platform om je workloads in te draaien, maar hoe houd je tegelijkertijd grip op je kosten? Hoe zet je de cloud slim in, zodat je ook op deze kosten kan besparen? Hoe houd je de kosten herleidbaar, zodat je ook per applicatie workload kan rapporteren?
            Aan het einde van deze interactieve workshop heb je hands-on tools en technieken om grip te krijgen op je cost management.
          </p>
          <li><Emphasis>Hoe migreer je je line-of-business applicaties naar Azure?</Emphasis></li>
          <p css={css`margin-bottom: 2em;`}>
            Je hebt een bedrijfskritische applicatie draaien die grotendeels uit maatwerk software bestaat, maar waar je tegen de grenzen aanloopt van schaalbaarheid en betrouwbaarheid. Voor deze applicatie zou je graag de voordelen die de publieke cloud biedt, willen uitnutten, maar waar begin je met deze migratie? Welke onderdelen lenen zich voor migreren en voor welke onderdelen moeten we op zoek naar een meer cloud-native oplossing? Welke migratiestrategie past het beste bij deze applicatie en welke valkuilen dien je te vermijden?
            In deze interactieve workshop lopen we met onze experts langs de diverse applicatie onderdeen en definiëren we het eerste hands-on plan om deze LOB applicatie te gaan migreren aan de hand van het Microsoft Cloud Adoption Framework (CAF).
          </p>
        </ul>
        <p>
          Voorafgaand komen wij graag eerst kennismaken en nemen we een kijkje binnen de omgeving. Dit helpt ons om een gerichte workshop te kunnen creëren. Dus is jouw organisatie op zoek naar kennis en echte ervaringen op het gebied van software development, cloud inrichting, pipelines of architectuur?
        </p>
        <p>
          Wij helpen je op weg!
        </p>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            min-height: 250px;
            margin: 1rem auto;
          `}
        >
          <img
            src={data.jasper.sharp.fluid.src}
            alt="Dit is Jasper"
            height="250px"
          />
          <img
            src={data.dennis.sharp.fluid.src}
            alt="En ik ben Dennis"
            height="250px"
            css={css`
              margin: 0 0 0 3rem;

              @media ${device.mobileL} {
                margin: 0;
              }
            `}
          />
        </div>
      </BodyContainer>
      <Contact />
      <Footer />
    </Layout>
  );
};

export default WerkenBijPage;
