import styled from '@emotion/styled';
import device from '../breakpoints';

const SmallLink = styled.a`
  text-decoration: none;
  position: relative;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-transform: lowercase;

  &::after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    transition: all .2s ease;
    transform-origin: 0 100%;
  }

  @media ${device.laptop} {
    font-size: 16px;

    &:hover {
      &:after {
        width: 0;
      }
    }
  }
`

export default SmallLink;