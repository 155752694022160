import React from 'react';
import styled from '@emotion/styled';
import LayoutContainer from '../helpers/layoutcontainer';
import CompTitle from '../helpers/comp-title';
import device from '../helpers/breakpoints';
import SmallLink from '../helpers/buttons/small-link';
import Anchor from '../helpers/anchor';

const Contact = () => {
  const Wrapper = styled.div`
    background-color: #5e9e4e;
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    padding: 3rem 0;

    ::before {
      border-style: solid;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      width: 0;
      z-index: 1;
      border-color: transparent transparent #5e9e4e;
      top: -1.46vw;
      border-width: 0 0 1.5vw 100vw;
      z-index: 9;
    }

    ::after {
      border-style: solid;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      width: 0;
      z-index: 1;
      border-color: transparent #5e9e4e transparent;
      bottom: -1.46vw;
      border-width: 0 100vw 1.5vw 0;
      z-index: 9;
    }
  `;

  const ExtendedTitle = styled(CompTitle)`
    color: #fff;
  `;

  const Container = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: row wrap;
  `;

  const MiniContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    p {
      text-align: left;
    }
  `;

  const Left = styled.div`
    position: relative;
    height: inherit;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 2rem;
    color: #fff;
    flex: 1 0 100%;

    @media ${device.laptop} {
      flex: 1 0 50%;
    }
  `;

  const Right = styled.div`
    position: relative;
    height: inherit;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 2rem;
    text-align: center;
    flex: 1 0 100%;

    @media ${device.laptop} {
      flex: 1 0 50%;
    }
  `;

  return (
    <Wrapper id="contact">
      <Anchor id="contact-anchor"></Anchor>
      <LayoutContainer>
        <Container>
          <ExtendedTitle>Contact</ExtendedTitle>
        </Container>
        <Container>
          <Left>
            <p>
              Meer weten? Wij maken graag een vrijblijvende afspraak om onze
              kennis en enthousiamse te delen. Wij bezoeken je graag op een
              locatie/tijdstip die ieders agenda het beste past.
            </p>
          </Left>
          <Right>
            <MiniContainer>
              <p>
                <SmallLink href="tel:0610394044">
                  06 – 10 39 40 44 (Jasper)
                </SmallLink>
                <br />
                <SmallLink href="tel:0611109466">
                  06 – 11 10 94 66 (Dennis)
                </SmallLink>
                <br />
                <SmallLink href="mailto:info@xprtz.net">
                  info@xprtz.net
                </SmallLink>
                <br />
              </p>
            </MiniContainer>
          </Right>
        </Container>
      </LayoutContainer>
    </Wrapper>
  );
};

export default Contact;
