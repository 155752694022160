import styled from '@emotion/styled';

 const Anchor = styled.div`
    display: block; 
    content: " "; 
    height: 120px;
    margin-top: -150px;
    visibility: hidden;   
  `;

  export default Anchor